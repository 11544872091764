<template>
  <div class="image-form-container card mt-3" @dragover.prevent="addDragAnimation"
       @dragleave.prevent="removeDragAnimation" @drop.prevent="dropFile"
       :class="{'dragging': isDragging}" :style="hideShadow ? 'box-shadow:none' : ''">
    <div class="card-header border-0">
      <h5 class="card-title">عکس ها</h5>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-start" style="gap: 5px">
        <div class="hidden-input-container">
          <b-button variant="primary">افزودن عکس</b-button>
          <input @input="fileAdded" ref="fileInput" type="file" accept="image/*" multiple class="hidden-input">
        </div>
        <b-button variant="danger" v-if="selectedImages.length" @click="removeSelectedImages">حذف ({{ selectedImages.length }} مورد)</b-button>
        <b-button variant="success" v-if="selectedImages.length === 1 && this.realValue.length > 1
&& !(realValue[0] && realValue[0].key == selectedImages[0])" @click="prioritizeSelectedImage">الویت دادن</b-button>
      </div>

      <div class="product-images-container">
        <draggable v-model="realValue">
          <transition-group tag="div" class="product-image-grid" name="product-image-grid">
            <div @click="(e) => {imageClicked(e, image.key)}"
                 :class="{'selected-image': selectedImages.includes(image.key)}"
                 class="product-image-cell" v-for="image in realValue" :key="image.key">
              <button @click="deleteImage(image.key)" class="delete-button btn btn-danger">
                <i class="fa fa-trash" data-toggle="tooltip"></i>
              </button>
              <ImageHolder class="image-holder">
                <img style="height: auto" :src="image.url">
              </ImageHolder>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import ProductImagesImpl from '@@/core/components/product/ProductImages/ProductImagesImpl'
export default {
  extends: ProductImagesImpl
}
</script>

<style scoped src="@@/core/components/product/ProductImages/ProductImagesStyle.css"></style>
